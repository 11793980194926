import api from './api';


class WorkloadService {
    async getWorkloadsRegionsAws(detailed) {
        var regions_list = {}
        const params = {
            'detailed': detailed            
          };
        const response = await api.get("/workload/regions/aws", { params } );
        regions_list = response.data.regions;        
        
        return regions_list;
    }  
    async getWorkloadsImprovementStatuses() {
        var improvement_statuses_list  = {}
        const params = { };
        const response = await api.get("/workload/improvement_status", { params } );
        improvement_statuses_list = response.data;        
        
        return improvement_statuses_list;
    }         
    async getWorkloads(detailed) {
        var workloads_list = {}
        const params = {
            'detailed': detailed            
          };
        const response = await api.get("/workload", { params } );
        workloads_list = response.data.workloads;        
        
        return workloads_list;
    } 
    async getWorkloadsNotifications() {
        var workloadsNotifications = {}
        const response = await api.get("/workload/notifications");

        workloadsNotifications = response.data;        
        
        return workloadsNotifications;
    } 
    async getWorkloadNotifications(workloadId) {
        var workloadsNotifications = {}
        const response = await api.get("/workload/"+workloadId+"/notifications");

        workloadsNotifications = response.data;        
        
        return workloadsNotifications;
    }         
    async getWorkload(id, detailed) {
        var workload_dict = {}
        const params = {
            'detailed': detailed            
          };


        const response = await api.get("/workload/"+id, { params } );
  
        workload_dict = response.data.workload;        
        
        return workload_dict;
    }
    async getWorkloadShares(id) {
        var workload_dict = {}
        const params = { };


        const response = await api.get("/workload/"+id+"/share", { params } );
  
        workload_dict = response.data.workload_share_summaries;        
        
        return workload_dict;
    }   
    async getWorkloadMilestones(id) {
        var workload_dict = {}
        const params = { };


        const response = await api.get("/workload/"+id+"/milestone", { params } );
  
        workload_dict = response.data.milestone_summaries;        
        
        return workload_dict;
    }     
    async getWorkloadMilestone(workload_id, milestone_id) {
        var workload_dict = {}
        const params = { };

        const response = await api.get("/workload/"+workload_id+"/milestone/"+milestone_id, { params } );
  
        workload_dict = response.data.milestone;        
        
        return workload_dict;
    }            
    async createWorkloadShare( workload_id, shared_with, permission_type) {
        var request = {
            "shared_with": shared_with,
            "permission_type": permission_type,
        }
        const response = await api.post("/workload/"+workload_id+"/share", request);
      
        return response.data;
    }  
    async createWorkloadMilestone( workload_id, milestone_name) {
        var request = {
            "milestone_name": milestone_name
        }
        const response = await api.post("/workload/"+workload_id+"/milestone", request);
      
        return response.data;
    } 

    
    async deleteWorkloadShare( workload_id, share_id) {
        const response = await api.delete("/workload/"+workload_id+"/share/"+share_id);
      
        return response.data;
    }  
    async createWorkload( customer_id, workload_env, workload_name, workload_desc, workload_account_ids, workload_regions, workload_region) {
        var request = {
            "customer_id": customer_id,
            "workload_env": workload_env,
            "workload_name": workload_name,
            "workload_desc": workload_desc,
            "workload_account_ids": workload_account_ids,
            "workload_regions": workload_regions,
            "workload_region": workload_region,
        }

        const response = await api.post("/workload", request);
      
        return response.data;
    }    
    async saveWorkload( workload_id, workload_env, workload_desc, workload_improvement_status, workload_pillar_priorities, workload_account_ids, workload_regions) {
        var request = {
            "workload_env": workload_env,
            "workload_desc": workload_desc,
            "workload_improvement_status": workload_improvement_status,
            "workload_pillar_priorities": workload_pillar_priorities,
            "workload_account_ids": workload_account_ids,
            "workload_regions": workload_regions,
        }
        const response = await api.put("/workload/"+workload_id, request);
        return response.data;
    }        
    async updateWorkloadLens(workloadId, lensAlias, currentLensVersion, latestLensVersion) {
        var request = {
            "milestone_name": "Upgrade_"+lensAlias+"_"+currentLensVersion+"_to_"+latestLensVersion
        }
        const response = await api.put("/workload/"+workloadId+"/lens/"+lensAlias+"/upgrade", request);
        return response.data;
    }      
    async getWorkloadHistory(id) {
        var workload_history_data = {}
        const response = await api.get("/workload/"+id+"/history");

        workload_history_data = response.data;        
        
        return workload_history_data;
    }   
    async deleteWorkload(workloadId) {
        const response = await api.delete("/workload/"+workloadId);

        return response.data;        
    }
  }
  
  export default new WorkloadService();